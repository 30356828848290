@page {
  size: A3;
  margin: 0;
}
@media not print {
  .only-print {
    display: none;
  }
}
@media print {
  body {
    font-weight: normal !important;
  }
  input {
    border: none !important;
    background-color: transparent !important;
    text-align: start !important;
  }
  [class$='-IndicatorsContainer'] {
    display: none !important;
  }
  [class$='-control'] {
    border: none !important;
    background-color: transparent !important;
    direction: rtl !important;
  }
  .no-print,
  .no-print *,
  .rdt_Pagination {
    display: none !important;
  }
  .sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 0 !important;
  }
  .table:not(.table-borderless) > :not(caption) > * > * {
    padding: 0 0.5rem !important;
  }
  .card {
    border: 0 !important;
  }
  body {
    &:before {
      content: 'فيات 2000';
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotateY(0deg) rotate(-45deg);
      font-size: 7rem;
      z-index: 999999999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #f0f0f0;
      opacity: 0.15;
    }
  }

  .card,
  .bg-light {
    background-color: transparent !important;
  }

  tbody {
    white-space: nowrap;
  }
}
